body {
  margin: 0;
  padding: 0 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;  /* Light background color for a playful feel */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Architects Daughter', cursive;
}

a {
  color: #54b6c1;  /* Primary color for links */
}

a:hover {
  color: #F1bb3f;  /* Secondary color for hovered links */
}

button {
  background-color: #54b6c1;  /* Primary color for buttons */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  text-transform: none; /* To ensure text is not transformed to uppercase */
}

button:hover {
  background-color: #F1bb3f !important;  /* Secondary color for hovered buttons */
}

input[type='text'], textarea {
  font-family: 'Poppins, Lato, Open Sans, sans-serif';
}
